<template>
  <div class="body container">
    <Breadcrumb />
    <div>
      <h1>{{ thePageTitle }}</h1>
      <hr class="separate-line" />
      <el-form label-width="200px" ref="form" :model="shortUrl">
        <el-form-item required prop="full_url" label="請輸入目的地網址">
          <el-input
            :value="shortUrl.full_url"
            @input="full_url => setShortUrl({ full_url })"
            :rules="commonRules.input"
          />
        </el-form-item>
        <el-form-item label="短網址名稱產生方式">
          <el-radio-group
            :value="shortUrl.short_url_is_random_generate"
            @input="
              short_url_is_random_generate =>
                setShortUrl({ short_url_is_random_generate })
            "
          >
            <el-radio :label="true">隨機產生</el-radio>
            <el-radio :label="false">指定短網址名稱</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="!shortUrl.short_url_is_random_generate">
          <el-form-item
            required
            prop="short_url_key"
            label="請輸入你要的短網址名稱"
          >
            <div class="flex">
              <div>https://ivy-way.com/l/</div>
              <el-input
                class="shortUrlKey"
                :value="shortUrl.short_url_key"
                @input="short_url_key => setShortUrl({ short_url_key })"
                :rules="commonRules.input"
              />
            </div>
          </el-form-item>
        </template>
        <template v-if="shortUrlVisible">
          <div class="displayShortUrl" align="center">
            產生的短網址名稱為
            <a target="_blank" :href="shortUrl.default_short_url">
              {{ shortUrl.default_short_url }}
            </a>
          </div>
          <div align="center">
            <el-button @click="() => $router.push({ name: 'shortUrls' })">
              回到短網址名稱列表
            </el-button>
            <el-button type="primary" @click="resetShortUrl">
              繼續產生短網址
            </el-button>
          </div>
        </template>
        <div v-else align="center">
          <el-button @click="() => $router.push({ name: 'shortUrls' })">
            {{ $t("button.cancel") }}
          </el-button>
          <el-button type="primary" @click="generateShortUrl">
            {{ $t("button.confirm") }}
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import formMixin from "@/mixins/form";

export default {
  metaInfo() {
    return {
      title: `${this.thePageTitle} - Ivy-Way Academy`
    };
  },
  mixins: [formMixin],
  components: {
    Breadcrumb
  },
  async created() {
    this.resetShortUrl();
  },
  computed: {
    thePageTitle() {
      return this.$t("pageTitle.addShortUrl");
    },
    shortUrl() {
      return this.$store.getters["shortUrls/shortUrl"];
    }
  },
  data() {
    return {
      generateRandomShortUrl: true,
      shortUrlVisible: false
    };
  },
  methods: {
    setShortUrl(value) {
      this.$store.commit("shortUrls/setShortUrl", value);
    },
    async generateShortUrl() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return;
      }

      await this.$store.dispatch("shortUrls/generateShortUrl");
      this.shortUrlVisible = true;
    },
    resetShortUrl() {
      this.shortUrlVisible = false;
      this.$store.commit("shortUrls/resetShortUrl");
    }
  }
};
</script>

<style scoped>
.flex {
  display: flex;
  width: 100%;
}

.flex > div {
  width: 140px;
}

.flex > .shortUrlKey {
  flex-grow: 1;
}

.displayShortUrl {
  margin-bottom: 20px;
}
</style>
